.aboutWrap {
  color: #191919;
  display: grid;
  text-align: left;
  font-size: 1.6em;
  min-height: 100vh;
  grid-auto-rows: min-content;
  align-self: center;
  align-content: center;
  justify-self: end;
  width: 100%;
  grid-template-columns: auto 1fr auto;
  text-align: left;
  grid-gap: 20px;
  background-color: #199515;
}

.aboutInner {
  grid-column: 2;
  display: grid;
  grid-gap: 10px;
  justify-self: center;
  max-width: 600px;
  padding: 40px;
  font-size: 1.1em;
  border: solid 25px #191919;
  box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  background-color: #242424;
  color: #12b90d;
  /*
      Above is shorthand for:
      border-top-left-radius: 255px 15px;
      border-top-right-radius: 15px 225px;
      border-bottom-right-radius: 225px 15px;
      border-bottom-left-radius:15px 255px;
      */
}
