.contactWrap {
  display: grid;

  font-size: 1.7em;
  color: #daefda;
  align-self: center;
  align-content: center;
  align-items: center;
  grid-template-columns: auto 1fr auto;
}

.contactInfo {
  grid-column: 2;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 2em;
  align-self: center;
}
.email {
  font-size: 1.3em;
}
