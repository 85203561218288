.projectElementWrap {
  position: relative;
  justify-self: center;
  border-radius: 12px;
}

.projectImage {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.imageLinkOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  transition: all ease-in-out 0.1s;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  box-shadow: rgba(6, 184, 6, 0.164) 0px 5px 15px;
  background: rgba(25, 25, 25, 0.2);
}

.imageLinkOverlay:hover {
  background-color: rgb(25, 149, 21, 0.98);
}

.projectTitle {
  font-weight: bolder;
  font-size: 1.1em;
  margin-top: 20px;
  text-decoration: underline;
}

.projectDesc {
  max-width: 250px;
  align-self: center;
}
