.elementHeader {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column: 2;
  display: grid;
  justify-items: center;
  font-size: 1.3em;
  font-weight: 900;
}

.headerLine {
  width: 40px;
  height: 2px;
  align-self: center;
  margin: 10px;
  margin-bottom: 20px;
}
