.introWrap {
  text-align: left;
  display: grid;
  height: 100vh;
  font-size: 3em;
  margin-left: 2em;
}

.introInner {
  max-width: 1000px;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 15px;
  justify-self: center;
  align-self: center;
}
.introInner h2 {
  font-size: 1.4em;
  color: #daefda;
}

.introName {
  font-size: 0.6em;
}
