.skillsWrap {
  height: 100vh;
  background-color: #199515;
  display: grid;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: center;
  font-size: 1.7em;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
}

.skillsInner {
  max-width: 600px;
  text-align: left;
  display: grid;
  grid-gap: 20px;
  grid-column: 2;
  justify-self: center;
  border: solid 25px #191919;
  padding: 40px;
  box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  background-color: #242424;
  color: #12b90d;
}
