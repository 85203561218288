.projectPage {
  font-size: 1.6em;
  padding-top: 5em;
  padding-bottom: 5em;
}
.projectWrap {
  min-height: 100vh;
  margin-top: 2em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: min-content;
  align-content: center;
  grid-gap: 2em;
}
