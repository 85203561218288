.footer {
  height: 3em;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #191919;
  color: #12b90d;
  font-size: 1.2em;
}
