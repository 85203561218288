.header {
  display: grid;
  grid-template-columns: 1fr auto;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 2em;
  background: rgba(25, 25, 25, 0.92);
  align-content: center;
  transition: all ease-in-out 0.3s;
  font-size: 3em;
  z-index: 1;
}

.headerLinks {
  grid-column: 2;
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: 0.45em;
  color: #daefda;
  grid-gap: 10px;
  margin: 1em;
  margin-right: 2.5em;
}

.linkWrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
}

.linkNum {
  color: #199515;
}

.linkWrap:hover {
  cursor: pointer;
  color: #199515;
}

.logo {
  display: grid;
  grid-column: 1;
  font-size: 0.28em;

  justify-content: start;
  margin: 1em;
  padding-left: 2.5em;
  min-width: 7em;
}
/* if window position, scroll pos top have box shadow, else none */
